<template>
  <v-container class="pt-4">
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="d-flex align-center rounded-lg pa-2" flat>
          <h4 class="" v-if="degree[0]">
            {{
              `${degree[0].departmentsName} / ${degree[0].stageName} / ${degree[0].sectionName} / ${degree[0].examMonth} / ${degree[0].subjectName} `
            }}
          </h4>
          <v-spacer></v-spacer>
          <v-btn tile color="primary" @click="back()" class="rounded-xl">
            {{ $t("back") }}
            <v-icon right> mdi-arrow-left </v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-3">
      <v-col>
        <v-card class="d-flex align-center rounded-lg">
          <v-card-actions>
            <v-btn color="secondary" @click="importFromXlsx = true">
              {{ $t("Import") }}
              <v-icon class="px-2">mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-btn color="primary" @click="exportToXlsx">
              {{ $t("Export") }}
              <v-icon class="px-2">mdi-microsoft-excel</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" md="12">
        <v-card class="d-flex align-center rounded-lg" flat>
          <DxDataGrid
            :column-hiding-enabled="true"
            id="gridContainer"
            :rtl-enabled="$i18n.locale == 'ar' ? true : false"
            :data-source="degree"
            :show-column-lines="showColumnLines"
            :show-row-lines="showRowLines"
            :show-borders="showBorders"
            :row-alternation-enabled="rowAlternationEnabled"
            :remote-operations="true"
            :repaint-changes-only="true"
            @saving="onSaving"
            :allow-column-resizing="false"
            :column-min-width="50"
            @saved="saveGridInstance"
            @editing-start="saveGridInstance"
            @edit-canceling="saveGridInstance"
            @edit-canceled="saveGridInstance"
            @row-updating="saveGridInstance"
            @row-updated="saveGridInstance"
            @keypress="saveGridInstance"
          >
            <DxPaging :page-size="10" />
            <DxPager
              :show-page-size-selector="true"
              :allowed-page-sizes="pageSizes"
              :show-info="false"
              :show-navigation-buttons="true"
              :visible="true"
            />
            <DxEditing
              mode="batch"
              :allow-adding="false"
              :allow-deleting="false"
              :allow-updating="true"
              class="txtRight"
            />
            <DxSearchPanel :visible="true" :placeholder="placeholder" />

            <!-- <DxColumn data-field="id" :allow-editing="false" /> -->
            <DxColumn
              data-field="fullName"
              :allow-editing="false"
              id="txtRight"
              :caption="$t('name')"
            />
            <DxColumn
              data-field="examDegree"
              :width="100"
              :caption="$t('degrees.theDegree')"
            >
              <DxRequiredRule :message="$t('degrees.ThisFieldIsRequired')" />
              <DxNumericRule :message="$t('degrees.ItShouldBeANumber')" />
              <DxRangeRule
                :message="$t('degrees.TheScoreMustBeetween0And100')"
                max="100"
                min="0"
              />
            </DxColumn>
            <DxColumn data-field="notex" :caption="$t('Notes')" />
          </DxDataGrid>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <div v-if="degree[0]">
          <v-alert
            prominent
            text
            outlined
            color="deep-orange"
            type="error"
            icon="mdi-progress-alert"
            v-if="!degree[0].isDone"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ $t("degrees.ThisListDoesNotAppear") }}
              </v-col>
              <v-col class="shrink">
                <v-btn
                  :disabled="hasEdit"
                  color="deep-orange"
                  dark
                  @click="degreeDone()"
                  >{{ $t("degrees.ending") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-alert>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="importFromXlsx"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ $t("ImportFromXlsx") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="importFromXlsx = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <degrees-import-from-xlsx :sessionGuid="id" :sectionGuid="id2" v-if="importFromXlsx"
           v-on:refresh-data-grid="getDegree"
          >
          </degrees-import-from-xlsx>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxSearchPanel,
  DxPager,
  DxPaging,
  DxColumnChooser,
  DxLoadPanel,
  DxRequiredRule,
  DxNumericRule,
  DxRangeRule,
} from "devextreme-vue/data-grid";
import "whatwg-fetch";
import DegreesImportFromXlsx from "../../components/degrees/degreesImportFromXlsx.vue";

export default {
  props: ["id", "id2"],
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxSearchPanel,
    DxPager,
    DxPaging,
    DxColumnChooser,
    DxLoadPanel,
    DxRequiredRule,
    DxNumericRule,
    DxRangeRule,
    DegreesImportFromXlsx,
  },
  data() {
    return {
      dialog: false,
      placeholder: this.$t("search"),
      rtlEnabled: true,
      showColumnLines: true,
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      overlay: false,
      degree: [],
      dataUpdata: [],
      pageSizes: [10, 15, 20],
      hasEditVar: false,
      importFromXlsx: false,
    };
  },
  created() {
    this.getDegree();
  },
  computed: {
    hasEdit() {
      return this.hasEditVar;
    },
  },
  methods: {
    saveGridInstance: function (e) {
      this.hasEditVar = e.component.hasEditData();
      // console.log(this.hasEditVar)
    },
    degreeDone() {
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: this.$t("IfYouClickYes"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            this.overlay = true;
            this.axios
              .post("Degrees/DegreesDone", {
                sessionGuid: this.id,
              })
              .then((res) => {
                this.getDegree();
                this.$swal.fire({
                  title: this.$t("operationAccomplishedSuccessfully"),
                  text: "",
                  icon: "success",
                  confirmButtonText: this.$t("close"),
                  toast: true,
                  position: "bottom-start",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener(
                      "mouseleave",
                      this.$swal.resumeTimer
                    );
                  },
                });
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              })
              .finally(() => {
                this.overlay = false;
              });
          }
        });
    },
    onSaving(e) {
      this.hasEditVar = e.component.hasEditData();
      //   this.overlay = true;
      this.dataUpdata = e.changes;
      // console.log( this.dataUpdata);
      const data = [];
      for (let i = 0; i < this.dataUpdata.length; i++) {
        data.push({
          sessionGuid: this.dataUpdata[i].key.sessionGuid,
          studentGuid: this.dataUpdata[i].key.studentGuid,
          note: this.dataUpdata[i].data.notex,
          degree: this.dataUpdata[i].data.examDegree,
        });
        if (!data[i].degree) {
          data[i].degree = this.dataUpdata[i].key.examDegree;
        }
        if (!data[i].note) {
          data[i].note = this.dataUpdata[i].key.notex;
        }
      }
      // console.log(this.dataUpdata);
      //   console.log(data);
      this.axios
        .post("Degrees/EditDegrees", data)
        .then((res) => {
          this.getDegree();
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    getDegree() {
      this.overlay = true;
      this.axios
        .get(
          `Degrees/GetStudentsDegrees?sessionGuid=${this.id}&sectionGuid=${this.id2}`
        )
        .then((res) => {
          this.degree = res.data.data;
          this.importFromXlsx = false;
          // console.log(this.degree);
        })
        .catch(() => {})
        .finally(() => {
          this.overlay = false;
        });
    },

    exportToXlsx() {
      this.overlay = true;
      this.axios({
        url: `Degrees/ExportToXlsx?sessionGuid=${this.id}&sectionGuid=${
          this.id2
        }&Lang=${localStorage.getItem("lang")}`,
        method: "GET",
        responseType: "arraybuffer",
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const filename = `${this.degree[0].departmentsName} - ${this.degree[0].stageName} - ${this.degree[0].sectionName} - ${this.degree[0].examMonth} - ${this.degree[0].subjectName}`;
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((e) => {
          console.log(e);
          this.$swal.fire({
            title: this.$t("TheFileDoesNotExist"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    back() {
      this.$router.go(-1);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasEdit) {
      this.$swal
        .fire({
          title: this.$t("errors.hasEdit"),
          text: this.$t("AreYouSureAboutTheOperation"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            next();
          }
        });
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
#txtRight {
  text-align: right !important;
}
</style>
